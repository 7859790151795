// Legal.js
import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Legal = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: July 29, 2024
        </Typography>
        <Typography variant="body2" paragraph>
          Welcome to CryptAuto. By accessing our website or using our services, you agree to be bound by these terms of service.
        </Typography>
        {/* ... Other terms ... */}
        {/* <Typography variant="body2" paragraph>
          Creators reserve the right to terminate any appointment early or mute the user if the user is abusive or mistreats them in any way, as determined by the creator. In such instances, the client will not be entitled to a refund and may be removed from the platform at the discretion of CryptAuto.
        </Typography>
        <Typography variant="body3" paragraph>
          If a creator does not show up to a session, the user will be entitled to a full refund.
        </Typography> */}
        {/* ... Other terms ... */}
      </Box>

      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: July 29, 2024
        </Typography>
        <Typography variant="body2" paragraph>
          At CryptAuto, we respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.
        </Typography>
        <Typography variant="body2" paragraph>
          We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
        </Typography>
        <Typography variant="body2" paragraph>
          We collect various types of information for various purposes to provide and improve our service to you. CryptAuto's use and transfer of information received from Google APIs to any other app will adhere to <a style={{ color: "white" }} href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank'>Google API Services User Data Policy</a>, including the Limited Use requirements.
        </Typography>
        <Typography variant="body2" paragraph>
          Types of Data Collected:
          <ul>
            <li>Personal Data: While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.</li>
            <li>Usage Data: We may also collect information on how the service is accessed and used.</li>
            <li>Tracking & Cookies Data: We use cookies and similar tracking technologies to track activity on our service.</li>
          </ul>
        </Typography>
        <Typography variant="body2" paragraph>
          Use of Data:
          <ul>
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>To allow you to participate in interactive features of our service when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our service</li>
            <li>To monitor the usage of our service</li>
            <li>To detect, prevent, and address technical issues</li>
          </ul>
        </Typography>
      </Box>
    </Container>
  );
};

export default Legal;
