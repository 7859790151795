import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AddDealership from './pages/AddDealership';
import AllDealerships from './pages/AllDealerships';
import DealershipDetail from './pages/DealershipDetail';
import LoginPage from './pages/Login';
import SignupPage from './pages/SignUpPage';
import UnauthorizedPage from './components/Unauthorized';
import Navbar from './components/Navbar/Navbar';
import fetchUser from './Users/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdditionalInfoPage from './pages/AdditionalInfoPage';
import EmailSignupPage from './pages/EmailSignupPage';
import Footer from './components/Footer/Footer';
import Legal from './components/Footer/Legal';
import axios from 'axios';
import baseUrl from './url';

function App() {
  axios.defaults.baseURL = `${baseUrl}`;
  axios.defaults.headers.common['apikey'] = process.env.REACT_APP_SUPABASE_KEY;

  // Use an interceptor to add the token to every request
  axios.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `${token}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const userData = await fetchUser();
      setUser(userData);
    };

    getUser();
  }, []);

  return (
    <>
      <div style={{ flex: 1 }}>
        <ToastContainer />
        <Navbar user={user} setUser={setUser} />
        <Routes>
          {/* <Route path="/" element={<LandingPage />} /> */}
          <Route path="/" element={<Home user={user} setUser={setUser} />} />
          <Route path="/add-dealership" element={<AddDealership />} />
          <Route path="/dealerships" element={<AllDealerships />} />
          <Route path="/dealerships/:name" element={<DealershipDetail />} />
          <Route path="/login" element={<LoginPage setUser={setUser} />} />
          <Route path="/signup" element={<SignupPage setUser={setUser} />} />
          <Route path="/additional-info" element={<AdditionalInfoPage setUser={setUser} />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/email-signup" element={<EmailSignupPage setUser={setUser} />} />
          <Route path="/legal" element={<Legal />} />
        </Routes>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
