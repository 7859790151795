import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper, CircularProgress } from '@mui/material';
import fetchUser from '../Users/utils';
import AdminHome from '../containers/Admin/AdminHome';

const Home = ({ user, setUser }) => {
  const [loading, setLoading] = useState(true); // Ensure loading state is initially set to true
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const queryUserId = queryParams.get('userId');
  const needsAdditionalInfo = queryParams.get('needsAdditionalInfo') === 'true';

  useEffect(() => {
    const handleAuthCallback = async () => {
      if (token && queryUserId) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', queryUserId);
        if (needsAdditionalInfo) {
          navigate('/additional-info');
        } else {
          const userData = await fetchUser();
          setUser(userData);
          window.history.replaceState(null, null, '/'); // Clear query parameters
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 500); // Ensures the spinner shows for at least 0.5 seconds
    };

    handleAuthCallback();
  }, [token, queryUserId, needsAdditionalInfo, navigate, setUser]);

  useEffect(() => {
    const getUser = async () => {
      if (!token && !queryUserId) {
        setLoading(true);
        const userData = await fetchUser();
        setUser(userData);
        setTimeout(() => {
          setLoading(false);
        }, 500); // Ensures the spinner shows for at least 0.5 seconds
      }
    };

    getUser();
  }, [setUser]);

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start', // Adjusts the position to the top
        height: '100vh',
        backgroundColor: 'background.default',
        pt: 8, // Adds padding to the top
      }}
    >
      <Box sx={{ mb: 4 }}>
        <img src="/cryptauto_banner_transparent.png" alt="CryptAuto Banner" style={{ width: '100%', maxWidth: '600px', height: 'auto' }} />
      </Box>

      {user?.is_admin ? (
        <AdminHome user={user} />
      ) : user && user.dealership ? (
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center', backgroundColor: 'grey.900' }}>
          <Typography variant="h4" gutterBottom>
            Welcome back, {user.name}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              component={Link}
              to={`/dealerships/${user.dealership.name.replace(/\s+/g, '-')}`}
              variant="contained"
              color="primary"
              size="large"
            >
              My Dealership
            </Button>
          </Box>
        </Paper>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Welcome to CryptAuto
          </Typography>
          <Button
            component={Link}
            to="/login"
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={() => setLoading(true)} // Ensure spinner shows immediately
          >
            Login
          </Button>
        </>
      )}
    </Container>
  );
};

export default Home;
