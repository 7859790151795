import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardActionArea, CardContent, CircularProgress } from '@mui/material';
import fetchUser from '../Users/utils';
import baseUrl from '../url';

const AllDealerships = () => {
  const [dealerships, setDealerships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const userData = await fetchUser();
      if (!userData || !userData.is_admin) {
        navigate('/unauthorized');
      } else {
        setUser(userData);
      }
    };

    getUser();
  }, [navigate]);

  useEffect(() => {
    const fetchDealerships = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/dealerships`);
        setDealerships(response.data);
      } catch (error) {
        console.error('Error fetching dealerships:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDealerships();
  }, []);

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Dealerships
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {dealerships.map((dealership) => (
          <Card key={dealership.id} sx={{ maxWidth: 345 }}>
            <CardActionArea component={Link} to={`/dealerships/${dealership.name}`}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {dealership.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {dealership.email}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AllDealerships;
