import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const PaymentsTable = ({ payments }) => {
  return (
    <div>
      <Paper sx={{ p: 4, maxWidth: 800, width: '100%', textAlign: 'center', backgroundColor: 'grey.900', color: 'white' }}>
        <Typography variant="h5" gutterBottom>
          Payments
        </Typography>
        {payments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((payment) => (
          <Box key={payment.id} sx={{ mb: 2, p: 2, border: '1px solid gray', borderRadius: '8px', backgroundColor: 'grey.800' }}>
            <Typography variant="body1"><strong>Customer Name:</strong> {payment.metadata?.customer_name || payment.name}</Typography>
            <Typography variant="body1"><strong>Customer Email:</strong> {payment.metadata?.customer_email}</Typography>
            <Typography variant="body1"><strong>Amount:</strong> {payment.amount} {payment.currency}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {payment.status}</Typography>
            <Typography variant="body1"><strong>Created At:</strong> {new Date(payment.created_at).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</Typography>
            <Typography variant="body1"><strong>URL:</strong> <a style={{ textDecoration: "underline", color: "inherit"}} href={payment.hosted_url}>link</a></Typography>
          </Box>
        ))}
      </Paper>
    </div>
  );
};

export default PaymentsTable;
