// Footer.js
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// import xLogo from "../../assets/xLogo.png";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'background.paper',
        boxShadow: 'none',
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 'auto',
        py: 3,
        px: { xs: 2, sm: 3 },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          '& a': { // This will apply the style to all Link components inside the Stack
            fontSize: { xs: '0.75rem', sm: '1rem' },
          },
          '& img': { // This will apply the style to the img tag inside the Stack
            width: { xs: '20px', sm: '24px' },
            height: { xs: '20px', sm: '24px' },
          }
        }}
        maxWidth="lg"
        margin="auto"
      >
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          style={{ margin: ".1rem 0" }}
          sx={{
            fontSize: { xs: '0.75rem', sm: '1rem' },
            order: { xs: -1, sm: 0 },
            width: { xs: '100%', sm: 'auto' }
          }}
        >
          &copy; {new Date().getFullYear()} CryptAuto
        </Typography>
        <Link style={{ margin: ".1rem 0" }} href="mailto:imports@nezumiracing.com" color="inherit">
          imports@nezumiracing.com
        </Link>
        {/* <Link style={{ margin: ".4rem 0 .1rem"}} href="https://x.com/pairplaygg" color="inherit">
          <img src={xLogo} alt="X logo" />
        </Link> */}
        <Link style={{ margin: ".1rem 0" }} href="/legal" color="inherit">
          Legal
        </Link>
        <Link style={{ margin: ".1rem 0" }} href="/faqs" color="inherit">
          FAQs
        </Link>
      </Stack>
    </Box>
  );
};

export default Footer;
