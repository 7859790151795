import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';


const AdminHome = (user) => {
  console.log("adminHomeUser", user);
  return (
    <Container sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h2" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="h6" gutterBottom>
        Welcome to the admin dashboard.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button
          component={Link}
          to="/add-dealership"
          variant="contained"
          color="primary"
          sx={{ mx: 2 }}
        >
          Add Dealership
        </Button>
        <Button
          component={Link}
          to="/dealerships"
          variant="outlined"
          color="primary"
          sx={{ mx: 2 }}
        >
          All Dealerships
        </Button>
      </Box>
    </Container>
  )
}

export default AdminHome;