import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PaymentModal from '../components/PaymentModal';
import baseUrl from '../url';
import PaymentsTable from './PaymentsTable';

const DealershipDetail = () => {
  const { name } = useParams();
  const [dealership, setDealership] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);

  const fetchPayments = async () => {
    if (dealership) {
      try {
        const response = await axios.get(`${baseUrl}/api/payments/${dealership.id}`);
        setPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    }
  };

  useEffect(() => {
    const fetchDealership = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/dealerships/${name}`);
        setDealership(response.data);
      } catch (error) {
        console.error('Error fetching dealership:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDealership();
  }, [name]);

  useEffect(() => {
    fetchPayments();
  }, [dealership]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dealership) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Dealership not found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
      <Paper sx={{ p: 4, maxWidth: 600, width: '100%', textAlign: 'center', backgroundColor: 'grey.900', color: 'white', mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {dealership.name}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Email: {dealership.email}
        </Typography>
        <Box sx={{ mt: 4 }}>
          <PaymentModal price={100} currency="USD" dealership={dealership} fetchPayments={fetchPayments} />
        </Box>
      </Paper>
      <>
        <PaymentsTable payments={payments} />
      </>
    </Box>
  );
};

export default DealershipDetail;
