import axios from 'axios';
import baseUrl from '../url';

const fetchUser = async () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  if (!token || !userId) {
    return null;
  }

  try {
    const response = await axios.get(`${baseUrl}/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user:', error);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    return null;
  }
};

export default fetchUser;
