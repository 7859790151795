import React from 'react';
import { Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import baseUrl from "../url";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

function InitialSignupPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const initiateGoogleSignup = () => {
    console.log("Redirecting to Google for authentication.");
    window.location.href = `${baseUrl}/api/google/login`;
  };

  const navigateToEmailSignup = () => {
    navigate('/email-signup');
  };

  return (
    <StyledContainer>
      <div style={isMobile ? { width: "60%" } : { width: "30%" }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={initiateGoogleSignup}
          style={{
            padding: "12px 10px",
            margin: "4px 0px 10px",
            color: "#FFFFFF",
            backgroundColor: "#4285F4",
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
          }}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google Logo"
            style={{
              width: "18px",
              marginRight: "8px",
              backgroundColor: "#FFFFFF",
            }}
          />
          Sign up with Google
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={navigateToEmailSignup}
          style={{
            padding: "12px 10px",
            margin: "4px 0px 10px",
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
          }}
        >
          Sign up with Email
        </Button>
      </div>
    </StyledContainer>
  );
}

export default InitialSignupPage;
