import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, Box, Typography, TextField, CircularProgress } from '@mui/material';
import QRCode from 'qrcode.react';
import baseUrl from '../url';

const PaymentModal = ({ dealership, fetchPayments }) => {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (fetchPayments) {
      fetchPayments(); // Trigger a refresh after the modal closes
    }
  };


  const handlePayment = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}/api/payments/create-charge`, {
        name: customerName,
        description: `Payment for ${customerName}`,
        local_price: { amount: price, currency: 'USD' },
        pricing_type: 'fixed_price',
        customer_email: customerEmail,
        dealership_id: dealership.id,
        dealership_name: dealership.name,
      });
      const { hosted_url } = response.data;
      setPaymentUrl(hosted_url);
      setLoading(false);
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Create Crypto Invoice
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {!paymentUrl && (
            <>
              <Typography variant="h6" gutterBottom>
                Create Crypto Invoice
              </Typography>
              <TextField fullWidth label="Customer Name" margin="normal" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
              <TextField fullWidth label="Customer Email" margin="normal" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
              <TextField fullWidth label="Price (USD)" margin="normal" value={price} onChange={(e) => setPrice(e.target.value)} />
            </>
          )}
          <Box sx={{ mt: 2 }} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

          }}>
            {!paymentUrl && !loading ?
              <Button variant="contained" color="primary" onClick={handlePayment}>
                Create Invoice
              </Button>
              : loading ?
                // show a spinner if loading
                <CircularProgress />
                :
                // make a link to the paymentUrl
                <Button variant="contained" color="primary" href={paymentUrl} target="_blank">
                  View Invoice
                </Button>
            }
          </Box>
          {paymentUrl && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="h6">Scan to Pay</Typography>
              <QRCode value={paymentUrl} />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PaymentModal;
