import React, { useState, useEffect } from 'react';
import { Button, Container, TextField, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import baseUrl from "../url";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { toast } from 'react-toastify';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginTop: '1rem',
  fontSize: '1.2rem',
});

function LoginPage({ setUser }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const initiateGoogleLogin = () => {
    console.log("Redirecting to Google for authentication.");
    window.location.href = `${baseUrl}/api/google/login`;
  };

  const handleAuthCallback = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const userId = urlParams.get('userId');

    if (token && userId) {
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      navigate('/'); // Redirect to home or any other route
      window.history.replaceState(null, null, '/'); // Clear query parameters
    } else {
      console.error('Authentication failed: Missing token or userId');
    }
  };

  useEffect(() => {
    if (window.location.search) {
      handleAuthCallback();
    }
  }, []);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please fill out all fields');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}/api/login`, { email, password });
      const { token, id } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', id);
      setUser({ id });
      navigate('/');
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("No account found with that email address");
      } else if (error.response?.status === 404) {
        toast.error('Invalid email or password');
      } else {
        toast.error('An error occurred logging in. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <Box sx={{ width: isMobile ? '80%' : '40%' }} style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1rem',
      }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ py: 1.5 }}
          onClick={initiateGoogleLogin}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google Logo"
            style={{
              width: "18px",
              marginRight: "8px",
              backgroundColor: "#FFFFFF",
            }}
          />
          Sign in with Google
        </Button>
        <Typography variant="h6" align="center" sx={{ my: 2 }}>
          or
        </Typography>
        <form onSubmit={handleEmailLogin} style={{ width: '100%', textAlign: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2, py: 1.5 }}
            type="submit"
            style={{
              marginBottom: theme.spacing(2), // 16px
            }}
            disabled={loading} // Disable button when loading
          >
            {loading ? <CircularProgress size={24} /> : 'Login with Email'}
          </Button>
        </form>
        <StyledLink to="/signup" style={{
          color: theme.palette.primary.main,
          textAlign: 'center',
          marginTop: '1rem',
        }}>
          Don't have an account? Sign up
        </StyledLink>
      </Box>
    </StyledContainer>
  );
}

export default LoginPage;
